import './App.css';
import './ldi/ldiRing.css';

import React, { useState, useEffect } from "react";
import sanityClient from "./Client";
import MetaData from "./MetaData";

export default function App() {

  const [landingContent, setLandingContent] = useState(null);

  /* Get the contents of the page */
  useEffect(() => {
      sanityClient.fetch(
          `*[_type == "siteSettings"][0] {
              leftLink,
              leftTitle,
              rightLink,
              rightTitle,
              GJMTitle,
              author,
              description,
              keyWords,
          }`            
      )
      .then((data) => setLandingContent(data))
      .catch(console.error);
  }, []);      /* brackets makes the code run once */

  if (!landingContent) return (<main><div class="ldi_Rng"><div></div><div></div><div></div><div></div></div>
  <br /><br /><br /></main>); // Load spinner

  return (
      <div className='body'>
          <MetaData 
                title={landingContent.GJMTitle}
                description={landingContent.description}
                keyWords={landingContent.keyWords}
                authorName={landingContent.author}
                />
          <div class="split left">
                <div id="textL">
                    <a id="linkL" href={landingContent.leftLink}>
                        {landingContent.leftTitle}
                    </a>
                    <br />
                </div>
          </div>

          <div class="split right">
                <div id="textR">
                    <a id="linkR" href={landingContent.rightLink}>
                        {landingContent.rightTitle}
                    </a>
                </div>
          </div>
          <div class="logo"></div> 
          <div class='siteName'>Gert Johnsen Media</div>
      </div>
  );
}

/*
<MetaData 
      title={landingContent.GJMTitle}
      description={landingContent.description}
      keyWords={landingContent.keyWords}
      authorName={landingContent.author}
      />
*/